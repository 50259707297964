import clsx from 'clsx';
import {FC, PropsWithChildren, ReactElement, ReactNode, isValidElement, useContext} from 'react';
import {twMerge} from 'tailwind-merge';
import {Portal} from '../../external/components/Portal/Portal';
import {Level, LevelContext} from '../../external/context/LevelContext';
import {TestableElement} from '../../external/types';
import {Alert, AlertDirection, AlertProps, AlertWidth} from '../Alert/Alert';
import {ButtonPrimary, ButtonPrimaryProps, ButtonPrimaryWidth} from '../Button/ButtonPrimary';
import {ButtonSecondary, ButtonSecondaryProps, ButtonSecondaryWidth} from '../Button/ButtonSecondary';
import {Icon, IconColor, IconSize, IconSvg} from '../Icon/Icon';

export enum ModalCloseActionSource {
  XIcon = 'XIcon',
  OkButton = 'OkButton',
  CancelButton = 'CancelButton',
  Backdrop = 'Backdrop',
}

export type ModalProps = {
  title?: ReactNode;
  text?: string;
  okButton?: ButtonPrimaryProps | ReactElement<ButtonPrimaryProps>;
  cancelButton?: ButtonSecondaryProps | ReactElement<ButtonSecondaryProps>;
  onClose?: (actionSource: ModalCloseActionSource) => void;
  show: boolean;
  className?: string;
  stickyButtons?: boolean;
  alert?: Pick<AlertProps, 'title' | 'description' | 'variant' | 'titleWrap'>;
  stickyHeader?: boolean;
  titleClassName?: string;
  fullScreen?: boolean;
  showCloseIcon?: boolean;
  centerButtons?: boolean;
  hideOverlay?: boolean;
} & TestableElement;

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  show,
  title = undefined,
  text = undefined,
  okButton = undefined,
  cancelButton = undefined,
  stickyButtons = false,
  alert = undefined,
  onClose = () => undefined,
  className = undefined,
  children = undefined,
  titleClassName = undefined,
  stickyHeader = false,
  fullScreen = false,
  showCloseIcon = true,
  centerButtons = false,
  testId = 'modal',
  hideOverlay = false,
}) => {
  const level = useContext(LevelContext);
  const styles = {
    component: twMerge(
      clsx(
        'rounded-[8px] max-w-[90vw] max-h-[90vh] bg-white px-[32px] pt-[32px] w-fit min-w-[416px] overflow-x-hidden',
        {
          'pb-[32px]': !stickyButtons,
        },
        className,
        {
          'max-w-[100vw] max-h-[100vh] h-[100%] w-[100%]': fullScreen,
        },
      ),
    ),
    title: twMerge(
      clsx('grow pr-[32px] font-bold text-[20px] leading-[24px] font-quicksand items-center', titleClassName),
    ),
    text: 'font-quicksand text-[16px] leading-[24px]',
    buttons: clsx('pt-[24px] flex justify-end gap-[16px]', {
      'justify-end': !centerButtons,
      'justify-center': centerButtons,
    }),
    bottomBox: clsx({
      'pb-[32px] sticky bottom-0 bg-white': stickyButtons,
    }),
    children: 'z-[0]',
    closeIcon: 'grow-0 shrink-0 cursor-pointer',
    header: twMerge(
      clsx('flex w-full z-[2] top-0 pb-[24px]', {
        '-mt-[32px] sticky top-0 -translate-y-[32px] bg-white pt-[32px] pb-[24px]': stickyHeader,
      }),
    ),
  };

  const handleButtonClick = (actionSource: ModalCloseActionSource, onClick?: () => unknown) => {
    (async () => {
      let suppressOnClose = false;
      if (onClick) {
        suppressOnClose = (await onClick()) === false;
      }
      if (!suppressOnClose) {
        onClose(actionSource);
      }
    })().catch(console.error);
  };

  const handleBackdropClick = () => {
    if (cancelButton !== undefined) {
      if (isValidElement(cancelButton)) {
        handleButtonClick(ModalCloseActionSource.Backdrop, cancelButton.props.onClick);
      } else {
        handleButtonClick(ModalCloseActionSource.Backdrop, cancelButton.onClick);
      }
    } else {
      onClose(ModalCloseActionSource.Backdrop);
    }
  };

  const renderBody = () => (
    <div className={styles.component} data-testid={testId} data-test-element="modal">
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {showCloseIcon && (
          <Icon
            className={styles.closeIcon}
            size={IconSize.LG}
            svg={IconSvg.CLOSE}
            color={IconColor.GREY_500}
            onClick={() => onClose(ModalCloseActionSource.XIcon)}
          />
        )}
      </div>
      {text && <div className={styles.text}>{text}</div>}
      {children && <div className={styles.children}>{children}</div>}

      <div className={styles.bottomBox}>
        {alert && (
          <div className="pt-[24px]">
            <Alert {...alert} direction={AlertDirection.VERTICAL} width={AlertWidth.FULL} />
          </div>
        )}

        {(okButton || cancelButton) && (
          <div className={styles.buttons}>
            {cancelButton &&
              (isValidElement(cancelButton) ? (
                cancelButton
              ) : (
                <ButtonSecondary
                  width={ButtonSecondaryWidth.BASE}
                  {...cancelButton}
                  onClick={() => handleButtonClick(ModalCloseActionSource.CancelButton, cancelButton.onClick)}
                />
              ))}
            {okButton &&
              (isValidElement(okButton) ? (
                okButton
              ) : (
                <ButtonPrimary
                  width={ButtonPrimaryWidth.BASE}
                  {...okButton}
                  onClick={() => handleButtonClick(ModalCloseActionSource.OkButton, okButton.onClick)}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Portal zIndex={level + Level.Modal} isOpen={show} backdrop={!hideOverlay} onClosePortal={handleBackdropClick}>
      <LevelContext.Provider value={level + Level.Modal}>{renderBody()}</LevelContext.Provider>
    </Portal>
  );
};
